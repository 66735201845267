import React, { useEffect } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import ArticlesComponent from "../components/articles"
import Seo from "../components/seo"

export const query = graphql`
  query NewsQuery {
    strapiNewspage {
      strapiId
      title
      slug
    }
    allStrapiNewsPage(sort: { fields: [strapiId], order: ASC }) {
      edges {
        node {
          strapiId
          title
          slug
        }
      }
    }
    allStrapiArticle(sort: { fields: [updated_at], order: DESC }) {
      edges {
        node {
          strapiId
          slug
          title
          Seo {
            metaDescription
          }
          category {
            name
            slug
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  width: 768
                  quality: 80
                  breakpoints: [768, 1024, 1280]
                  layout: CONSTRAINED
                  aspectRatio: 1.7777777777777
                )
              }
            }
          }
          user {
            username
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    width: 50
                    height: 50
                    quality: 100
                    layout: FIXED
                  )
                }
              }
            }
          }
          updated_at
        }
      }
    }
  }
`

const NewsPage = ({ data }) => {
  useEffect(() => {
    const script = document.createElement("script")

    script.src = "/js/_2_grid-switch.js"
    script.async = true
    script.defer = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const articles = data.allStrapiArticle.edges
  const newsPages = data.allStrapiNewsPage.edges
  const news = data.strapiNewspage

  return (
    <Layout>
      <Seo title={data.strapiNewspage.title + " | Vacational Studies"} />

      <div id="sub-header" className="bg-contrast-lower">
        <div className="container max-width-adaptive-lg margin-bottom-sm">
          <div className="subnav subnav--expanded@sm js-subnav">
            <button className="reset btn btn--subtle margin-y-sm subnav__control js-subnav__control">
              <span>Show Categories</span>
              <svg
                className="icon icon--xxs margin-left-xxs"
                aria-hidden="true"
                viewBox="0 0 12 12"
              >
                <polyline
                  points="0.5 3.5 6 9.5 11.5 3.5"
                  fill="none"
                  strokeWidth="1"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></polyline>
              </svg>
            </button>

            <div className="subnav__wrapper js-subnav__wrapper">
              <nav className="subnav__nav">
                <button
                  className="reset subnav__close-btn js-subnav__close-btn js-tab-focus"
                  aria-label="Close navigation"
                >
                  <svg className="icon" viewBox="0 0 16 16">
                    <g
                      strokeWidth="1"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                    >
                      <line x1="13.5" y1="2.5" x2="2.5" y2="13.5"></line>
                      <line x1="2.5" y1="2.5" x2="13.5" y2="13.5"></line>
                    </g>
                  </svg>
                </button>

                <ul className="subnav__list">
                  <li className="subnav__item" key={`page__${news.slug}`}>
                    <Link className="subnav__link" to={`/${news.slug}`}>
                      {news.title}
                    </Link>
                  </li>
                  {newsPages.map((newspage, i) => {
                    return (
                      <li
                        className="subnav__item"
                        key={`page__${newspage.node.slug}`}
                      >
                        <Link
                          className="subnav__link"
                          to={`/news/${newspage.node.slug}`}
                        >
                          {newspage.node.title}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <ArticlesComponent articles={articles} />
    </Layout>
  )
}

export default NewsPage
