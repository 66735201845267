import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Moment from "react-moment"

const Card = ({ article }) => {
  return (
    <article className="js-grid-switch__item">
      <div className="card-v10 card-v10--state-1 height-100% js-grid-switch__content">
        <Link
          to={`/article/${article.node.slug}`}
          className="card-v10__img-link radius-lg shadow-lg"
        >
          <GatsbyImage
            image={{
              ...article.node.image.localFile.childImageSharp.gatsbyImageData,
            }}
            alt={article.node.title}
            style={{ position: "static" }}
          />
        </Link>

        <div className="card-v10__content-wrapper">
          <div className="card-v10__content bg shadow-sm radius-lg">
            <div className="card-v10__body">
              <div className="margin-bottom-xs">
                <Link
                  to={`/category/${article.node.category.slug}`}
                  className="story__category"
                >
                  {article.node.category.name}
                </Link>
              </div>

              <div className="text-component">
                <h1 className="card-v10__title">
                  <Link
                    to={`/article/${article.node.slug}`}
                    className="color-contrast-higher"
                  >
                    {article.node.title}
                  </Link>
                </h1>
                <p className="card-v10__excerpt color-contrast-medium">
                  {article.node.Seo.metaDescription}
                </p>
              </div>
            </div>

            <footer className="card-v10__footer">
              <div className="story__author">
                <GatsbyImage
                  className="story__author-image"
                  image={{
                    ...article.node.user.image.localFile.childImageSharp
                      .gatsbyImageData,
                  }}
                />
                <div>
                  <address className="story__author-name">
                    {article.node.user.username}
                  </address>
                  <p className="story__meta">
                    <Moment format="D MMMM YYYY">
                      {article.node.updated_at}
                    </Moment>
                  </p>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </article>
  )
}

export default Card
