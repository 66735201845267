import React from "react"
import Card from "./card"

const Articles = ({ articles }) => {
  return (
    <div className="articles margin-top-sm margin-bottom-lg">
      <div className="container max-width-adaptive-lg">
        <div
          className="grid-switch js-grid-switch"
          data-gs-item-class-1="col-12 col-4@md"
          data-gs-item-class-2="col-12"
          data-gs-content-class-1="card-v10--state-1"
          data-gs-content-class-2="card-v10--state-2"
        >
          <div className="margin-bottom-sm display@md no-js:is-hidden">
            <div className="btns inline-flex btns--radio js-grid-switch__controller">
              <div>
                <input
                  type="radio"
                  name="radio-view-option"
                  id="radio-grid"
                  value="1"
                  defaultChecked
                />
                <label
                  className="btns__btn btns__btn--icon"
                  htmlFor="radio-grid"
                >
                  <svg className="icon icon--xs" viewBox="0 0 16 16">
                    <title>Grid</title>
                    <g>
                      <path d="M6,0H1C0.4,0,0,0.4,0,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1V1C7,0.4,6.6,0,6,0z"></path>
                      <path d="M15,0h-5C9.4,0,9,0.4,9,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1V1C16,0.4,15.6,0,15,0z"></path>
                      <path d="M6,9H1c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1v-5C7,9.4,6.6,9,6,9z"></path>
                      <path d="M15,9h-5c-0.6,0-1,0.4-1,1v5c0,0.6,0.4,1,1,1h5c0.6,0,1-0.4,1-1v-5C16,9.4,15.6,9,15,9z"></path>
                    </g>
                  </svg>
                  <span className="margin-left-xs text-sm">Grid</span>
                </label>
              </div>

              <div>
                <input
                  type="radio"
                  name="radio-view-option"
                  id="radio-list"
                  value="2"
                />
                <label
                  className="btns__btn btns__btn--icon"
                  htmlFor="radio-list"
                >
                  <svg className="icon icon--xs" viewBox="0 0 16 16">
                    <title>List</title>
                    <g>
                      <rect width="16" height="3"></rect>
                      <rect y="6" width="16" height="3"></rect>
                      <rect y="12" width="16" height="3"></rect>
                    </g>
                  </svg>
                  <span className="margin-left-xs text-sm">List</span>
                </label>
              </div>
            </div>
          </div>
          <div className="grid gap-lg">
            {articles.map((article, i) => {
              return <Card article={article} key={`${article.node.slug}`} />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Articles
